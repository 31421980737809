$(function(){

// タブ
const tab     = '.tab';
const label   = $(tab).find('.tab-label');
const content = $(tab).find('.tab-content');
const tag     = label.find('a');
const current = 'current';
//
showTabContent();
//
tag.on('click', function(e){
  const self = $(this);
  e.preventDefault();
  label.find('li').removeClass(current);
  self.parent().addClass(current);
  showTabContent();
});
//
function showTabContent(){
  const id = $( '.' + current ).find('a').attr('href');
  content.find('>*').hide();
  $(id).fadeIn();
}
// タブ

// PageTop
const pagetopMd = $('#pagetopMd');
// $(window).on('scroll', function () {
//   if( $(this).scrollTop() > 100 ) {
//     pagetop.fadeIn();
//   } else {
//     pagetop.fadeOut();
//   }
// });
//
pagetopMd.on('click', function(){
  $('body, html').animate({ scrollTop : 0 }, 400);
  return false;
});
// PageTop

// PageTop
const pagetopSm = $('#pagetopSm');
$(window).on('scroll', function () {
  if( $(this).scrollTop() > 100 ) {
    pagetopSm.fadeIn();
  } else {
    pagetopSm.fadeOut();
  }
});
pagetopSm.on('click', function(){
  $('body, html').animate({ scrollTop : 0 }, 400);
  return false;
});
// PageTop

// アコーディオン
$('.accordion dt').on('click', function(){
  $(this).next('dd').slideToggle(180);
  $(this).toggleClass('open');
  $(this).next('dd').siblings('dd').slideUp(100);
  $(this).siblings('dt').removeClass('open');
});
// アコーディオン

//SmoothScroll
$('a[href^="#"]').click(function() {
  var speed = 400;
  var href= $(this).attr("href");
  var target = $(href == "#" || href == "" ? 'html' : href);
  var position = target.offset().top;
  $('body,html').animate({scrollTop:position}, speed, 'swing');
  return false;
});

});
